import { QueryClient } from '@tanstack/react-query';
import { Entry } from 'contentful';
import { productKeys } from 'features';
import { contentfulKeys } from 'features/contentful';
import { fetchContentForTags } from 'features/contentful/connector';
import { fetchProducts, fetchProductsByQuery } from 'features/product/connector';
import { ContentfulBuildVariable } from 'types/Contentful';
import {
  IBlockImage,
  IContentSlot,
  IContentSlotFields,
  IPageCategory,
  IPageCategoryFields,
  IPageCmsFields,
  IPageContent,
  IPageContentFields,
  IPageExternalFields,
  IPageHome,
  IPageHomeFields,
  IPageHybrid,
  IPageHybridFields,
  IProductSelector,
  ITag,
  ITagSelector,
} from 'types/ContentfulTypes';
import { CONTENTFUL_TYPES } from '../components/Contentful/ContentfulFields/types';
import { BLOCK_IMAGE_SIZES, CF_LOCALES, REVERSED_CF_LOCALES } from '../constants/contentful';
import getBuildVariables from './ContentfulBuildVariablesUtil';
import getContentfulClient from './contentfulClient';
import { log } from './loggerUtil';

const DEFAULT_INCLUDE_DEPTH = 8;

export const getCFLocaleByLanguageCode = (languageCode: string) => CF_LOCALES[languageCode];

export const getLanguageCodeByCFLocale = (CFLocale: string) => REVERSED_CF_LOCALES[CFLocale];

type BrandField = { fields: { value: string } };

export const validateValueFieldDataWithValue = (data: BrandField[], value: string) =>
  data?.some(({ fields }) => fields?.value?.toLowerCase() === value?.toLowerCase());

type ContentTypeFields =
  | IPageContentFields
  | IPageCategoryFields
  | IPageHybridFields
  | IPageContentFields
  | IContentSlotFields
  | IPageHomeFields
  | IPageCmsFields
  | IPageExternalFields;

export const matchBrand =
  ({ brand }: ContentfulBuildVariable) =>
  (content: Entry<ContentTypeFields>) => {
    if (content?.sys?.contentType?.sys?.id === CONTENTFUL_TYPES.PAGE_EXTERNAL) {
      return true;
    }

    // Not the ideal way to handle this but the CF Entry<> type makes it impossible to typeguard (atleast from what I can see)
    const refinedContent = content as Entry<Exclude<ContentTypeFields, IPageExternalFields>>;
    const brands = refinedContent?.fields?.brands;

    return !brands || validateValueFieldDataWithValue(brands, brand);
  };

export const mapLocalizedPageToLocalizedUrlObject = (page: IPageContent, isPreview = false) => {
  if (!page) {
    return null;
  }

  const { slug } = page.fields;

  return Object.entries(slug).map(([CFLocale, localizedslug]) => {
    const locale = getLanguageCodeByCFLocale(CFLocale);
    return {
      key: getLanguageCodeByCFLocale(CFLocale),
      value: `/${locale}${isPreview ? '/contentful-preview' : ''}/${localizedslug}`,
    };
  });
};

type LocaleItem = {
  key: string;
  value: string;
};

const excludeNonMatchingLocales = (items: LocaleItem[], locales: string[]) =>
  items?.filter(({ key, value }) => value && locales?.some((loc) => loc === key));

interface ContentfulBaseParams {
  includeDepth?: number;
  isPreview?: boolean;
  locale: string;
}

interface GetContentfulContentPageParams extends ContentfulBaseParams {
  locales?: string[];
  slug?: string;
}

export const getContentfulContentPage = async ({
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
  locales,
  slug,
}: GetContentfulContentPageParams) => {
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient && slug && locale) {
    const result = await contentfulClient.getEntries<IPageContentFields>({
      content_type: CONTENTFUL_TYPES.PAGE_CONTENT,
      'fields.slug': slug,
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
    });

    const page = result.items.find(matchBrand(getBuildVariables()));
    const includedEntries = result.includes?.Entry as Entry<unknown>[];
    const productSelectors = getProductSelectorsForEntries(includedEntries);
    const tagSelectors = getTagSelectorsForEntries(includedEntries);

    if (page) {
      const localizedPage = (await contentfulClient.getEntry<IPageContent>(page?.sys.id, {
        // Doesn't allow multiple locales
        content_type: page.sys.contentType.sys.id,
        locale: '*',
        select: 'fields.slug',
      })) as unknown as IPageContent;

      if (localizedPage) {
        const mappedObject = mapLocalizedPageToLocalizedUrlObject(localizedPage, isPreview);

        if (!mappedObject) return;

        const localizedUrls = locales ? excludeNonMatchingLocales(mappedObject, locales) : mappedObject;
        return { localizedUrls, page, productSelectors, tagSelectors };
      }

      return { page, productSelectors, tagSelectors };
    }
  }

  return null;
};

interface GetContentfulCategoryPageParams extends ContentfulBaseParams {
  categoryCode: string;
}

export const getContentfulCategoryPage = async ({
  categoryCode,
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
}: GetContentfulCategoryPageParams) => {
  if (categoryCode && locale) {
    const contentfulClient = getContentfulClient(isPreview);
    const result = await contentfulClient.getEntries<IPageCategoryFields>({
      content_type: CONTENTFUL_TYPES.PAGE_CATEGORY,
      'fields.categoryCode': categoryCode,
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
    });
    const page = result.items.find(matchBrand(getBuildVariables()));
    const includedEntries = result.includes?.Entry as Entry<unknown>[];
    const productSelectors = getProductSelectorsForEntries(includedEntries);
    const tagSelectors = getTagSelectorsForEntries(includedEntries);

    return { page, productSelectors, tagSelectors };
  }
};

interface GetContentfulSlotParams extends ContentfulBaseParams {
  pathname: string;
}

export const getContentfulSlot = async ({
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
  pathname,
}: GetContentfulSlotParams) => {
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient && pathname && locale) {
    const result = await contentfulClient.getEntries<IContentSlotFields>({
      content_type: CONTENTFUL_TYPES.CONTENT_SLOT,
      'fields.pathname': pathname,
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
    });
    const slot = result.items.find(matchBrand(getBuildVariables()));
    return slot as IContentSlot;
  }

  return null;
};

interface GetContentfulContentForTagsParams extends ContentfulBaseParams {
  tags: ITag[];
}

export const getContentfulContentForTags = async ({
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
  tags,
}: GetContentfulContentForTagsParams) => {
  const contentfulClient = getContentfulClient(isPreview);
  const tagIds = tags?.map((tag) => tag?.sys?.id);

  if (contentfulClient && locale && !!tags?.length) {
    const result = await contentfulClient.getEntries<IPageContentFields>({
      content_type: CONTENTFUL_TYPES.PAGE_CONTENT,
      ...(tags?.length && {
        'fields.tags.sys.id[all]': tagIds?.join(','),
      }),
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
      order: 'sys.createdAt',
    });

    const brandFilteredItems = result?.items?.filter(matchBrand(getBuildVariables()));
    return brandFilteredItems as IPageContent[];
  }

  return null;
};

interface GetContentfulContentEntryParams extends ContentfulBaseParams {
  entryId: string;
}

export const getContentfulContentEntry = async ({
  entryId,
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
}: GetContentfulContentEntryParams) => {
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient && locale && entryId) {
    const result = await contentfulClient.getEntry(entryId, {
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
    });

    return result;
  }

  return null;
};

export const getContentfulPageSlugs = async (locale: string, isPreview = false) => {
  const selectFields = ['slug', 'brands'].flatMap((field) => `fields.${field}`) as `fields.${string}`[];
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient && locale) {
    const result = await contentfulClient.getEntries<IPageContentFields>({
      content_type: CONTENTFUL_TYPES.PAGE_CONTENT,
      locale: getCFLocaleByLanguageCode(locale),
      select: selectFields,
    });

    // Postfiltering due to CF client not allowing reference fields with more than one entry to be searchable
    return result.items.filter(matchBrand(getBuildVariables()))?.map(({ fields }) => fields?.slug);
  }

  return [];
};

export const entryIsPublished = (entry: { fields: unknown }) => !!entry?.fields;

export const filterUnpublishedEntries = (entries: { fields: unknown }[]) =>
  entries?.filter((entry) => entryIsPublished(entry));

export const getContentfulPageHybrid = async ({
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
  slug,
}: {
  includeDepth?: number;
  isPreview?: boolean;
  locale: string;
  slug: string;
}) => {
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient && slug && locale) {
    const result = await contentfulClient.getEntries<IPageHybridFields>({
      content_type: CONTENTFUL_TYPES.PAGE_HYBRID,
      'fields.slug': slug,
      include: includeDepth,
      locale: getCFLocaleByLanguageCode(locale),
    });

    const page = result.items.find(matchBrand(getBuildVariables()));

    return page;
  }

  return null;
};

export const getContentfulPageHome = async ({
  includeDepth = DEFAULT_INCLUDE_DEPTH,
  isPreview = false,
  locale,
}: GetContentfulContentPageParams) => {
  const contentfulClient = getContentfulClient(isPreview);

  if (contentfulClient) {
    try {
      const homePages = await contentfulClient.getEntries<IPageHomeFields>({
        content_type: CONTENTFUL_TYPES.PAGE_HOME,
        include: includeDepth,
        locale: getCFLocaleByLanguageCode(locale),
      });
      // We filter all homepages on current brand here because CF client doesn't allow us to do it in the query
      const page = homePages.items.find(matchBrand(getBuildVariables()));

      // We need to fetch the homepage again with the specific id to get the contained product selectors
      const brandHomePage = await contentfulClient.getEntries<IPageHome>({
        content_type: CONTENTFUL_TYPES.PAGE_HOME,
        include: includeDepth,
        locale: getCFLocaleByLanguageCode(locale),
        'sys.id': page?.sys?.id,
      });
      const includedEntries = brandHomePage.includes?.Entry as Entry<unknown>[];
      const productSelectors = getProductSelectorsForEntries(includedEntries);
      if (
        (page?.fields?.banners && !!filterUnpublishedEntries(page?.fields?.banners)?.length) ||
        (page?.fields?.content && !!filterUnpublishedEntries(page?.fields?.content)?.length)
      ) {
        return { page, productSelectors };
      }
    } catch (e) {
      log('getContentfulPageHome - contentfulUtil', `Failed to load Contentful Homepage`, e);
    }
  }

  return null;
};

/**
 * Function which fetches all slugs of the specified contentType
 * @param {object} mobileImage - mobile image object containg a url and title
 * @param {object} mediumImage - medium image object containg a url and title
 * @param {object} wideImage - wide image object containg a url and title
 * @returns {array} - array of mapper images with fallbacks
 */
export const mapResponsiveContentfulImages = (
  mobileImage?: IBlockImage,
  mediumImage?: IBlockImage,
  wideImage?: IBlockImage,
) => [
  {
    image: mobileImage || mediumImage || wideImage,
    size: BLOCK_IMAGE_SIZES.SMALL,
  },
  {
    image: mediumImage || wideImage || mobileImage,
    size: BLOCK_IMAGE_SIZES.MEDIUM,
  },
  {
    image: wideImage || mediumImage || mobileImage,
    size: BLOCK_IMAGE_SIZES.WIDE,
  },
];

export const buildPageUrl = (contentModel: string, destination: string, locale: string) => {
  if (contentModel === CONTENTFUL_TYPES.PAGE_PLP) {
    return `/${locale}/c/${destination}`;
  }

  if (contentModel === CONTENTFUL_TYPES.PAGE_PDP) {
    return `/${locale}/p/${destination}`;
  }

  if (contentModel === CONTENTFUL_TYPES.PAGE_CMS || contentModel === CONTENTFUL_TYPES.PAGE_CONTENT) {
    return `/${locale}/${destination}`;
  }

  return destination;
};

const getUpdatedAtValue = (element: Entry<unknown>) => element?.sys?.updatedAt;

const getLatestDate = (dates: string[]) => dates?.reduce((a, b) => (a > b ? a : b));

export const getContentfulPageDate = (page: IPageCategory | IPageContent | IPageHybrid) => {
  const dates = [getUpdatedAtValue(page)];

  const template = page?.fields?.template;

  if (template) {
    dates.push(getUpdatedAtValue(template));
  }

  return getLatestDate(dates);
};

export const handleContentfulRobots = (contentfulPage: IPageContent | IPageCategory | IPageHybrid) =>
  contentfulPage?.fields?.indexed ? 'index, follow' : 'noindex, nofollow';

const getProductSelectorsForEntries = (entries: Entry<unknown>[]) => {
  const selectors = entries?.filter(
    (entry) => entry.sys.contentType.sys.id === CONTENTFUL_TYPES.PRODUCT_SELECTOR,
  ) as IProductSelector[];
  const productCodes = selectors?.map((selector) => {
    switch (selector?.fields?.products?.searchType) {
      case 'QUERY':
        return {
          id: selector?.sys?.id,
          pushCnCToBack: selector?.fields?.pushCnCToBack,
          query: selector?.fields?.products?.searchQuery?.[getLanguageCodeByCFLocale(selector?.sys?.locale)],
          showOutOfStock: selector?.fields?.showOutOfStockProducts,
          type: 'QUERY',
        };
      case 'CATEGORY':
        return {
          categoryCode: selector?.fields?.products?.categoryCode,
          id: selector?.sys?.id,
          pushCnCToBack: selector?.fields?.pushCnCToBack,
          showOutOfStock: selector?.fields?.showOutOfStockProducts,
          type: 'CATEGORY',
        };
      case 'PRODUCT':
      default:
        return {
          codes: selector?.fields?.products?.activeItems?.map((product: { code: string }) => product?.code) || [],
          id: selector?.sys?.id,
          pushCnCToBack: selector?.fields?.pushCnCToBack,
          showOutOfStock: selector?.fields?.showOutOfStockProducts,
          type: 'PRODUCT',
        };
    }
  });
  return productCodes;
};

type PrefetchProductSelectorsParams = {
  locale: string;
  productSelectors: ReturnType<typeof getProductSelectorsForEntries>;
  queryClient: QueryClient;
};

export const prefetchProductSelectors = async ({
  locale,
  productSelectors,
  queryClient,
}: PrefetchProductSelectorsParams) => {
  const queries = productSelectors
    ?.map(({ categoryCode, codes, pushCnCToBack = false, query, showOutOfStock = false, type }) => {
      switch (type) {
        case 'QUERY':
          return query
            ? queryClient.fetchQuery({
                queryFn: () =>
                  fetchProductsByQuery({
                    currentPage: 0,
                    filterOnStock: !showOutOfStock,
                    locale,
                    pageSize: 5,
                    pushCnCToBack,
                    query,
                  }),
                queryKey: productKeys.query({
                  currentPage: 0,
                  filterOnStock: !showOutOfStock,
                  locale,
                  pageSize: 5,
                  pushCnCToBack,
                  query,
                }),
              })
            : null;
        case 'CATEGORY':
          return categoryCode
            ? queryClient.fetchQuery({
                queryFn: () =>
                  fetchProductsByQuery({
                    currentPage: 0,
                    filterOnStock: !showOutOfStock,
                    locale,
                    pageSize: 5,
                    pushCnCToBack,
                    query: categoryCode,
                  }),
                queryKey: productKeys.query({
                  currentPage: 0,
                  filterOnStock: !showOutOfStock,
                  locale,
                  pageSize: 5,
                  pushCnCToBack,
                  query: categoryCode,
                }),
              })
            : null;
        case 'PRODUCT':
          return codes
            ? queryClient.fetchQuery({
                queryFn: () =>
                  fetchProducts({
                    filterOnStock: !showOutOfStock,
                    locale,
                    productCodes: codes,
                    pushCnCToBack,
                  }),
                queryKey: productKeys.products({
                  filterOnStock: !showOutOfStock,
                  locale,
                  productCodes: codes,
                  pushCnCToBack,
                }),
              })
            : null;
        default:
          return null;
      }
    })
    .filter(Boolean);
  return Promise.allSettled(queries);
};

export const getTagSelectorsForEntries = (entries: Entry<unknown>[]) => {
  const tags = entries?.filter(
    (entry) => entry.sys.contentType.sys.id === CONTENTFUL_TYPES.TAG_SELECTOR,
  ) as ITagSelector[];
  return tags;
};

export const prefetchTagSelectors = async (tagSelectors: ITagSelector[], queryClient: QueryClient, locale: string) => {
  const queries = tagSelectors
    ?.map((tagSelector) => {
      const tags = tagSelector?.fields?.tags;
      const mappedTags = tags?.map((tag) => tag?.fields?.tagId);
      return tags?.length
        ? queryClient.prefetchQuery({
            queryFn: () => fetchContentForTags(tags, locale),
            queryKey: contentfulKeys.contentfulTaggedContent(mappedTags, locale),
          })
        : null;
    })
    .filter(Boolean);
  return Promise.all(queries.filter((query) => query));
};
